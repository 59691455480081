.css-selector {
	color: var(--color-white);
	background: linear-gradient(254deg, #ffff00, #f14444, #9a109a);
	background-size: 600% 600%;
	-webkit-animation: AnimationName 3s ease infinite;
	-moz-animation: AnimationName 3s ease infinite;
	animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
	0% {
		background-position: 88% 0%;
	}
	50% {
		background-position: 13% 100%;
	}
	100% {
		background-position: 88% 0%;
	}
}
@-moz-keyframes AnimationName {
	0% {
		background-position: 88% 0%;
	}
	50% {
		background-position: 13% 100%;
	}
	100% {
		background-position: 88% 0%;
	}
}
@keyframes AnimationName {
	0% {
		background-position: 88% 0%;
	}
	50% {
		background-position: 13% 100%;
	}
	100% {
		background-position: 88% 0%;
	}
}
