.smart-style-2 #header,
#header {
	height: 64px !important;
	padding: 16px;
	display: flex;
	justify-content: start;
	align-items: center;
	background: $color-primary;
	border-bottom: 0px none !important;
	z-index: 1500;

	&> :first-child,
	aside {
		width: unset;
		// margin-right: 28px;
	}
}

// Todo burger menu start
.hidden-menu #hide-menu> :first-child>a {
	width: 46px !important;
	box-shadow: none;
	color: $text-primary;
	line-height: 24px;
}

#hide-menu> :first-child>a,
.btn-header a {
	margin-top: 0px !important;
}

.btn-header> :first-child>a {
	background-color: transparent;
}

.btn-header a {
	margin-top: 0px !important;
	background-color: transparent;
	box-shadow: none;
}

.full-screen #fullscreen> :first-child>a,
.hidden-menu #hide-menu> :first-child>a {
	background-image: none;
	background-color: transparent;
	box-shadow: none;

	&:active {
		box-shadow: none;
	}
}

.btn-header.pull-right {
	margin: 0px;
}

// Todo burger menu end
#logo-group {
	width: unset !important;
}

#logo {
	width: auto;
	padding: 1px;
	margin: 0;
}

.fixed-header #header {
	box-shadow: none;
}

.logo-text {
	&__tw {
		font-size: 1.125rem;
		font-weight: 700;
		color: $text-primary;
		// line-height: 49px;
	}
}

.btn-header> :first-child>a {
	border: none !important;
	margin: 0;
}

ul.info-page {
	display: none;
}

button.function-btn,
#header>div.function-btn {
	height: 49px;
	line-height: 49px;
	font-size: 1rem;
	font-weight: 700;

	&-mobile {
		display: block;
	}

	&-pc {
		display: none;
	}
}

#header>div.function-btn-ctn {
	display: none !important;
}

header#header .p-avatar {
	width: 3rem !important;
	height: 3rem !important;
	background-color: #976401;
	color: white !important;
}

@media screen and (min-width: 979px) {

	.smart-style-2 #header,
	#header {
		height: 80px !important;
		padding: 0px 24px;

		>#logo-group {
			height: auto;
		}

		> :first-child,
		aside {
			display: none;
			width: auto;
			padding: 1px 2px;
		}
	}

	.fixed-header #header {
		box-shadow: none;
	}

	.countdown {
		min-width: fit-content;
		font-size: $default-font-size;
		color: $color-light;
	}

	#mobile-profile-img a.userdropdown img {
		border: none !important;
		color: $color-white;
	}

	.user-box {
		text-align: center;
		display: inline-block;
		min-width: 80px;
		font-size: $default-font-size;
		color: $color-light;
	}

	#logo {
		width: auto;
		padding: 1px;
		margin: 0;
	}

	.logo-text {
		&__tw {
			font-size: 1.5rem;
		}
	}

	.btn-header> :first-child>a {
		border: none !important;
		margin: 0;
	}

	.dropdown-menu.pull-right {
		background-color: $color-primary-dark;
		top: 10px !important;
	}

	.dropdown-menu>li>a {
		display: block;
		background-color: $color-white;
		padding: 10px !important;
		cursor: pointer;
	}

	.dropdown-menu .divider {
		padding: 5px;
		background-color: $color-white;
		margin: 0 !important;
	}

	.dropdown-menu>li>a:focus,
	.dropdown-menu>li>a:hover {
		background-color: $color-primary;
	}

	.dropdown-menu {
		&__ul {
			width: 100%;
			padding: 5px;
			display: flex;
			flex-direction: column;
			display: none;
		}

		&__icon {
			margin-right: 10px;
		}
	}

	.dropdown-img {
		width: 20%;
	}

	.dropdown_header {
		display: block;
		padding: 5px;
		margin-bottom: 0;
		white-space: nowrap;

		&__text {
			margin-bottom: 10px;
			white-space: break-spaces;
		}
	}

	.dropdown-item.active,
	.dropdown-item:active {
		background-color: $color-gray-light;
	}

	.menu-on-top aside#left-panel nav>ul>li>a {
		height: auto;
	}

	.menu-on-top .menu-item-parent {
		font-size: $default-font-size !important;
	}

	ul.info-page {
		display: flex !important;
		margin-bottom: 0px;
		padding-left: 0px;
		overflow-x: hidden !important;
		overflow-y: hidden !important;

		li,
		a {
			height: 80px;
			list-style-type: none;
			font-size: 1.1rem;
			font-weight: 600;
			line-height: 80px;
			display: flex !important;
			align-items: center;
		}

		a:active {
			background-color: transparent !important;
			color: #444444 !important;
		}

		a:hover {
			background-color: transparent !important;
			color: #444444 !important;
		}

		li {
			// margin-left: 72px;
		}
	}

	button.function-btn,
	#header>div.function-btn {
		&-mobile {
			display: none;
		}

		&-pc {
			display: block;
		}
	}

	#header>div.function-btn-ctn {
		display: flex !important;
	}
}

// #entrance-nav {
// 	display: flex;
// 	margin-bottom: 0px;
// 	padding-left: 0px;
// 	background-color: red !important;
// }