.u-center-text {
	text-align: center !important;
}

.u-margin-bottom-big {
	margin-bottom: 8rem !important;
}

.u-margin-bottom-medium {
	margin-bottom: 5rem !important;
}

.u-margin-bottom-small {
	margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
	margin-top: 8rem !important;
}

.u-margin-top-huge {
	margin-top: 10rem !important;
}

.u-margin-top-small {
	margin-top: 1.5rem !important;
}

.u-margin-left-small {
	margin-left: 2rem !important;
}
.u-margin-right-small {
	margin-right: 2rem !important;
}

.u-margin-right-tiny {
	margin-right: 1rem !important;
}

.u-padding-rigth-small {
	padding: 2rem !important;
}

.d-flex {
	// padding-bottom: 1em;
	// margin-bottom: 0.5rem;
	// justify-content: space-between;
	// display: flex;
	// align-items: baseline;
}
.d-flex-even {
	padding-bottom: 1em;
	margin-bottom: 0.5rem;
	justify-content: space-evenly;
	display: flex;
	align-items: baseline;
}

.d-flex-warp {
	justify-content: space-evenly;
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
}

.d-flex-column {
	display: flex;
	align-items: stretch;
	flex-direction: column;
}

.u-padding-5 {
	padding: 5rem;
}
.u-padding-3 {
	padding: 3rem;
}

.u-float-right {
	float: right;
}

.u-float-left {
	float: left;
}

.h-5 {
	height: 5rem;
}

.h-8 {
	height: 8rem;
}

.bg-primary {
	background-color: var(--color-primary) !important;
	color: var(--color-white) !important;
}
.bg-blue {
	background-color: var(--color-blue) !important;
	color: var(--color-white) !important;
}
.bg-warning {
	background-color: $color-warning !important;
	color: var(--color-white) !important;
}
.bg-danger {
	background-color: $color-danger !important;
	color: var(--color-white) !important;
}
.bg-info {
	background-color: $color-info !important;
	color: var(--color-white) !important;
}
.bg-success {
	background-color: var(--color-success) !important;
	color: var(--color-white) !important;
}
.bg-teal {
	background-color: var(--color-teal) !important;
	color: var(--color-white) !important;
}
.bg-confirm {
	background-color: $color-orange !important;
	color: var(--color-white) !important;
}
.bg-edit {
	background-color: $color-edit !important;
	color: var(--color-white) !important;
}


.txt-color-primary {
	color: var(--color-primary) !important;
}
.txt-color-blue {
	color: var(--color-blue) !important;
}
.txt-color-warning {
	color: $color-warning !important;
}
.txt-color-danger {
	color: $color-danger !important;
}
.txt-color-info {
	color: $color-info !important;
}
.txt-color-green {
	color: var(--color-green) !important;
}
.txt-color-teal {
	color: var(--color-teal) !important;
}
.txt-color-confirm {
	color: $color-orange !important;
}
.txt-color-edit {
	color: $color-edit !important;
}

