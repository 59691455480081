input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-form-field {
  // font-size: 1.4rem;
  width: 70%;
}

.label-strong {
  font-weight: 500;
}

table > tbody > tr > th {
  padding: 0.15rem 1rem 0.1rem 1rem;
  width: 9rem;
  border-bottom: 1px solid var(--color-white);
  background-color: var(--color-grey-light-2);
}
table > tbody > tr > td {
  padding-left: 1rem;
  word-break: break-all;
}

textarea {
  resize: none;
}