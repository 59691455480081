@import "../abstracts/variables";

html,
body {
	min-height: 100%;
	background: $bgc-primary;
	font-family: "Noto Sans TC", sans-serif;
	font-size: 1rem;
	color: $text-primary;
}
