/* GLOBAL MAT-DIALOG FORM STYLE */

.small-dialog-container .mat-dialog-container {
    width: 500px;
    height: 500px;
    padding: 0;
}

.medium-dialog-container .mat-dialog-container {
    width: 660px;
    height: 600px;
    padding: 0;
}


.big-dialog-container .mat-dialog-container {
    width: 860px;
    height: 550px;
    padding: 0;
}

.large-dialog-container .mat-dialog-container {
    width: 1800px;
    height: 670px;
    padding: 0;
}


.huge-dialog-container .mat-dialog-container {
    width: 1800px;
    height: 703px;
    padding: 0;
}

.wide-dialog-container .mat-dialog-container {
    width: 1200px;
    height: 358px;
    padding: 0;
}

.defend-dialog-container .mat-dialog-container {
    width: 1200px;
    height: 491px;
    padding: 0;
}

.long-dialog-container .mat-dialog-container {
    width: 800px;
    height: 700px;
    padding: 0;
}



