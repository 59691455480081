.page-footer {
	width: 100%;
	height: 40px;
	display: block;
	padding: 13px 13px 0;
	padding-left: 233px;
	border: none;
	background: $color-primary-dark;
	color: $color-white;
	position: unset !important;
	font-weight: 400;
	font-size: 0.875rem;
}

.smart-style-2 .page-footer,
.smart-style-2 .superbox-show {
	background: $color-primary-dark !important;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
