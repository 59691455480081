// colors
:root {
	--color-primary-light: #2c4e7e;
	--color-primary: #213362;
	--color-primary-dark: #111d3b;

	--color-secondary-light: #8d269a;
	--color-secondary: #42275f;
	--color-secondary-dark: #312653;

	--color-white: #fff;
	--color-gray: #6c757d;
	--color-gray-light: #b9b8b8;
	--color-gray-dark: #343a40;
	--color-black: #000;
	--color-light: #bfbfbf;

	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-grey: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--shadow-all: 0 0 2rem rgba(0, 0, 0, 0.4);

	--bg-transparant: rgba(255, 255, 255, 0.8);

	--color-blue: #3c82f1;
	--color-red: #ee4648;
	--color-orange: #fd7e14;
	--color-yellow: #f59f2e;
	--color-green: #2ac566;
	--color-teal: #1da2b7;

	--color-success: #2ac566;
	--color-info: #64748a;
	--color-warning: #f59f2e;
	--color-danger: #ee4648;
	--color-edit: #1da2b7;
}

$color-primary-light: #ffe0a5;
$color-primary: #ffc453;
$color-primary-dark: #976401;

$color-secondary-light: #9c9c9c;
$color-secondary: #686868;
$color-secondary-dark: #444444;

$color-white: #fff;
$color-gray: #6c757d;
$color-gray-light: #f4f4f4;
$color-gray-dark: #343a40;
$color-black: #000;
$color-light: #bfbfbf;

$shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
$shadow-grey: 0 2rem 5rem rgba(0, 0, 0, 0.06);
$shadow-all: 0 0 2rem rgba(0, 0, 0, 0.4);

$bg-transparant: rgba(255, 255, 255, 0.8);

// 告警
$color-blue: #3c82f1;
$color-red: #ee4648;
$color-orange: #fd7e14;
$color-yellow: #f59f2e;
$color-green: #2ac566;
$color-teal: #1da2b7;

$color-success: #45bb50;
$color-info: #64748a;
$color-warning: #f59f2e;
$color-danger: #f94d4d;
$color-edit: #1da2b7;

$default-font-size: 1rem;

// font
$util-font-sizes: 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
	66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 88, 90;

// 產生字型大小 class
@each $util-font-size in $util-font-sizes {
	.font-size-#{$util-font-size} {
		font-size: $util-font-size * 0.063rem !important;
	}
}

// ! VRS START
$primary: #ffc453;
$primary-dark: #744d00;
$primary-light: #ffe0a5;
$secondary: #ffe0a5;
$danger: #f96d6d;

$success: rgb(134, 222, 124);
$success-dark: #314834;

$warning: #f0b00b;

$grey1: #141414;
$grey2: #d0d0d0;
$grey3: #d9d9d9;
$grey4: #f0f0f0;
$white: #ffffff;
$pink: #af4c4c;
$inactive: #9c9c9c;
$annotate: #6e6d6d;
$input-border: $inactive;
$info: #91cff6;
$info-dark: #2a445d;

$danger-light: #da4646;
$danger-dark: #470000;

$bgc-primary: #fffcf8;

$text-primary: #444444;
$text-grey: #686868;
$text-light: $inactive;

$theme-colors: (
	primary: $primary,
	primary-dark: $primary-dark,
	secondary: $secondary,
	danger: $danger,
	warning: $warning,
	success: $success,
	pink: $pink,
	info: $info,
	danger-dark: $danger-dark,
	danger-light: $danger-light,
);

$text-colors: (
	primary: $text-primary,
	secondary: $text-grey,
	light: $annotate,
	danger: $danger,
	warning: $warning,
	success: $success,
	hint: $primary-dark,
);

@each $key, $value in $text-colors {
	.text-color-#{$key} {
		color: $value;
	}
}
