@import "./../../abstracts/variables";

// Todo primeNg util style
.p-component {
	font-family: "Noto Sans TC", sans-serif;
	color: $text-primary;
}

// Todo button style
.p-button {
	min-width: 100px;
	padding: 8px 12px;
	background: $primary;
	border: 1px solid $primary;
	border-radius: 10px;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 20px;

	&:enabled:hover {
		background: $primary;
		border: 1px solid $primary;
	}
}

@each $key, $value in $theme-colors {
	.p-button-#{$key} {
		height: 3rem;
		padding: 10px 20px;
		background: $value !important;
		border: 1px solid $value !important;
		border-radius: 10px;

		@if $key == "primary" {
			color: $primary-dark !important;
		} @else if $key == "danger" {
			color: $danger-dark !important;
		} @else if $key == "success" {
			color: $success-dark !important;
		} @else if $key == "info" {
			color: $info-dark !important;
		} @else {
			color: white;
		}

		font-size: 1rem;
		font-weight: 700;

		&:enabled:hover {
			background: $value !important;
			border: 1px solid $value !important;
			color: if($key == "primary", $primary-dark, white);
		}

		&:disabled {
			opacity: 0.5;
		}

		&:focus {
			box-shadow:
				0 0 0 2px #ffffff,
				0 0 0 4px $value,
				0 1px 2px 0 black;
		}

		&:enabled:active {
			background: $value;
			color: #ffffff;
			border-color: $value;
			color: if($key == "primary", $primary-dark, white);
		}
	}
}

.p-button-frame {
	height: 3rem;
	padding: 10px 20px;
	background: white !important;
	border: 1px solid $primary-dark !important;
	border-radius: 10px;
	color: $primary-dark;
	font-size: 1rem;
	font-weight: 700;

	&:enabled:hover {
		background: white !important;
		border: 1px solid $primary-dark !important;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.p-button-outline {
	height: 48px;
	padding: 0px 24px;
	border: 1px solid $primary-dark;
	border-radius: 10px;
	background-color: $white;
	color: $primary-dark;

	&:focus {
		box-shadow:
			0 0 0 2px #ffffff,
			0 0 0 4px $primary,
			0 1px 2px 0 black;
	}

	&:enabled:hover {
		color: $primary-dark;
	}

	&:enabled:active {
		background: $primary;
		color: $primary-dark;
		border-color: $primary;
	}
}

// Todo table style
.p-datatable {
	color: $text-primary;

	.p-datatable-header {
		height: 58px;
		padding: 10px 16px;
		background-color: white;
		border-width: 0px;
		font-weight: 400;
		font-size: 0.938rem;
		color: $text-primary;
		line-height: 22px;
	}

	.p-sortable-column:not(.p-highlight):hover {
		background-color: $grey3;
		color: $text-primary;
	}

	.p-paginator-bottom {
		background-color: white;
		border-width: 0px;
		font-weight: 400;
		font-size: 0.938rem;
		line-height: 22px;
		padding-top: 16px;
		// border-bottom: 1px solid $inactive;

		p-dropdown,
		.p-dropdown {
			height: 40px;
			padding: 0px;
			// border: 0px none;
		}

		.p-dropdown-label {
			padding: 2px;
			padding-left: 8px;
			line-height: 30px;
		}
	}

	.p-datatable-wrapper {
		.p-datatable-thead {
			> tr {
				> th {
					padding: 16px;
					background-color: $grey3;
					border-left: 1px solid $inactive;
					border-right: 1px solid $inactive;
					border-bottom: 0px none;
					font-weight: 400;
					font-size: 0.938rem;
					line-height: 22px;
					color: $text-primary;

					&:first-child {
						border-left: 0px solid $inactive;
					}

					&:last-child {
						border-right: 0px solid $inactive;
					}

					input,
					.p-dropdown {
						padding: 8px;
						background-color: white;
						border: 0px solid $inactive;
						border-radius: 0px;

						&::placeholder,
						.p-placeholder {
							color: $annotate !important;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.p-datatable-tbody > tr {
		> td {
			padding: 21px 1rem;
			background-color: transparent;
			border: 1px solid $inactive;
			border-width: 0 1px 0 0;
			font-weight: 400;
			font-size: 0.938rem;
			line-height: 22px;
			color: $text-primary;
			border-bottom: 1px solid $inactive;

			&:last-child {
				border-right: 0px none;
			}

			.p-button {
				height: 2.5rem;
			}
		}

		&:nth-child(2n) > td {
			background-color: $grey3;
		}
	}

	.p-paginator .p-paginator-current,
	.p-paginator-first,
	.p-paginator .p-paginator-prev,
	.p-paginator .p-paginator-next,
	.p-paginator .p-paginator-last {
		color: $text-primary;
	}

	.p-paginator .p-paginator-pages .p-paginator-page {
		min-width: 30px;
		height: 30px;
		border: 0 none;
		background-color: transparent;
		border-radius: 4px;
		color: black;
		text-align: center;

		&.p-highlight {
			background-color: $primary;
			color: $primary-dark;
		}
	}

	.p-sortable-column {
		.p-sortable-column-icon {
			color: $text-primary;
		}

		&.p-highlight .p-sortable-column-icon {
			color: $text-primary;
		}
	}

	.p-paginator p-dropdown {
		display: flex;
		align-items: center;
		gap: 0.4rem;
		padding-left: 0.4rem;
	}

	.p-paginator p-dropdown::before {
		content: "顯示";
		margin-bottom: 3px;
		min-width: max-content;
	}

	.p-paginator p-dropdown::after {
		content: "筆";
		margin-bottom: 3px;
		min-width: max-content;
	}
}

// Todo checkbox
.p-checkbox .p-checkbox-box.p-highlight {
	border-color: $primary;
	background: $primary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
	border-color: $primary;
	background: $primary;
	color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
	border-color: $primary;
}

// Todo radio button
.p-radiobutton .p-radiobutton-box {
	width: 20px;
	height: 20px;
	border: 2px solid $text-primary;

	&.p-highlight {
		border-color: $primary;
		background-color: $primary;
	}

	&:not(.p-disabled):not(.p-highlight):hover {
		border-color: $primary;
	}

	&.p-highlight:not(.p-disabled):hover {
		border-color: $primary;
		background-color: $primary;
	}
}

// p-blockUI z-index
.p-blockui-document.p-component-overlay {
	z-index: 1000;
}

.p-confirm-dialog {
	z-index: 100000 !important;
}

// 麵包屑
.p-breadcrumb {
	width: 100%;
	font-size: 1.25rem !important;
	background: #e9e9e9;
	border: 1px solid #e9e9e9;
	padding: 10px 10px 10px 1px;
}

/** toast 提示**/

.p-toast {
	z-index: 12000 !important;
	width: 380px !important;
	opacity: 1;
	font-size: 16px;

	.p-toast-message-icon {
		display: none;
	}

	.p-toast-message {
		// warn
		&.p-toast-message-warn {
			// border-left: 3px solid #ffda18 !important;
			border-left: 0px !important;
			background-color: #937323;
			color: #fff;
		}

		// success
		&.p-toast-message-success {
			// border-left: 3px solid #1bfc75 !important;
			border-left: 0px !important;
			background-color: #037f43;
			color: #fff;
		}

		&.p-toast-message-error {
			// border-left: 3px solid #fc1b1b !important;
			border-left: 0px !important;
			background-color: #903d3d;
			color: #fff;
		}

		// 隱藏icon
		.pi {
			font-size: 25px;
			color: #fff;
		}
	}
}

// .p-toast {
// 	z-index: 12000 !important;
// 	width: 380px !important;
// 	opacity: 1;
// 	font-size: 1rem;
// 	.pi {
// 		font-size: 1.563rem;
// 	}
// }

.p-password-panel {
	z-index: 12000 !important;
}

.p-chips,
.p-multiselect,
.p-inputnumber,
.p-password,
.p-inputtext {
	width: 100%;
	padding-top: 14px;
	padding-bottom: 14px;

	&:enabled:hover,
	&:enabled:focus {
		box-shadow: 0 0 0 0.15rem #bfdbfe !important;
	}

	&.ng-dirty.ng-invalid:hover,
	&.ng-dirty.ng-invalid:focus {
		box-shadow: 0 0 0 0.15rem #febfbf !important;
	}

	&::placeholder,
	&::-moz-placeholder,
	&::-webkit-input-placeholder {
		color: $annotate !important;
		opacity: 1;
	}
}

.p-dropdown {
	width: 100%;

	&::placeholder,
	&::-moz-placeholder,
	&::-webkit-input-placeholder {
		color: $annotate !important;
		opacity: 1;
	}
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	color: $color-white;
	background: $primary;
}

.p-inputgroup {
	.p-inputtext {
		// border-right: 0px none;
	}

	.p-inputtext:hover + .p-inputgroup-addon,
	.p-inputtext:focus + .p-inputgroup-addon {
		border-color: #3b82f6;
	}

	.p-inputtext.ng-dirty.ng-invalid + .p-inputgroup-addon {
		border-color: #e24c4c !important;
	}

	.p-inputgroup-addon {
		border-left: 0px none;
		background: transparent;
		color: $primary-dark;
	}
}

p-inputnumber,
.p-inputnumber {
	width: 100%;
}

.p-calendar {
	width: 100%;
	height: 48px;

	.p-inputtext {
		border-right: 0px;
	}

	& > .p-button.p-button-icon-only {
		min-width: unset;
		height: 48px !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		background-color: transparent;
		border: 1px solid #ced4da;
		border-left: 0px;
		color: $primary-dark;
	}
}

.p-inputgroup {
	& > .p-button {
		border-radius: 0 !important;
	}
}

.p-dropdown-item.p-ripple {
	white-space: normal;
}

// 覆蓋 TabView 預設背景設
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background-color: unset;
	color: #9fadba;
	display: flex;
	justify-content: center;
	padding: 12.5px;
}

.p-tabview .p-tabview-nav {
	background: none;
	border: unset;

	li {
		min-width: 130px;
	}
}

.p-component,
.p-fluid .p-dropdown .p-dropdown-label,
.p-inputtext,
// .p-dialog .p-dialog-content,
.p-chips .p-chips-multiple-container .p-chips-input-token input {
	font-size: $default-font-size;
}

// ::ng-deep .p-dialog .p-resizable-handle {
// 	position: absolute;
// 	font-size: 0.006rem !important;
// 	display: block;
// 	cursor: se-resize;
// 	width: 12px;
// 	height: 12px;
// 	right: 1px;
// 	bottom: 1px;
// }

.p-dialog {
	// padding: 32px 24px;
}

.p-dialog .p-dialog-header {
	padding-bottom: 0px;

	.p-dialog-title {
		width: 100%;
		padding-bottom: 8px;
		font-size: 1.125rem;
		font-weight: 500;
		border-bottom: 1px solid $grey3;
	}
}

.p-dialog .p-dialog-content {
	justify-content: center;
	flex-direction: column;
	padding: 16px 24px;
}

.p-dialog .p-dialog-footer {
	padding: 16px 24px !important;
	padding-top: 36px !important;
}

.p-inputtext,
.p-multiselect-label {
	line-height: 18px;
}

.p-column-filter-overlay {
	min-width: 175px;
}

.p-paginator {
	padding: 5px 10px;
}

.p-paginator,
.p-link,
.p-paginator .p-dropdown,
.p-paginator .p-dropdown .p-dropdown-label {
	font-size: 1.063rem;
}

.p-button.p-button-icon-only {
	height: 40px !important;
	width: 40px;
}

.p-button.p-button-icon-only.p-button-rounded {
	border-radius: 50% !important;
	height: 30px;
}

.p-password {
	.pi {
		margin-top: -10px !important;
		font-size: 1.25rem !important;
	}
}

.p-button {
	padding: 14px 20px !important;
	border-radius: 5px !important;
}

/** 日期元件 **/
// .p-datepicker-group {
// 	position: relative;
// 	top: -2px;
// }
.p-datepicker table,
.p-datepicker .p-timepicker span {
	font-size: 1.188rem !important;
}

.p-datepicker table th {
	text-align: center;
}

.p-datepicker {
	z-index: 10000 !important; // 讓 日期元件 在 dialog 之上
}

.p-datepicker-inline {
	position: absolute !important;
}

.p-datepicker table td > span {
	width: 39px;
	height: 39px;
}

.p-datepicker table td {
	padding: 0;
}

/** 灰階顯示 **/
input.ui-state-disabled,
input.ui-widget:disabled {
	background: $color-gray-light !important;
	opacity: 0.9 !important;
}

input.p-disabled,
input.p-component:disabled,
.p-dropdown.p-disabled,
.p-dropdown.p-component:disabled,
textarea.p-disabled,
textarea.p-component:disabled,
.p-multiselect.p-disabled,
.p-multiselect.p-component:disabled {
	background: $color-gray-light !important;
	opacity: 0.9 !important;
}

.p-calendar-disabled {
	& > .p-button:disabled {
		color: #ced4da !important;
		border: 1px solid #ced4da !important;
	}
}

.p-inputgroup-addon {
	min-width: 34px !important;
}

/** chips */
.p-chips .p-chips-multiple-container {
	// padding: 5px;
}

.p-chips .p-chips-multiple-container .p-chips-token {
	margin-bottom: 5px;
}

/** Progress Bar **/
.p-progressbar .p-progressbar-value {
	background: $color-success !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	box-shadow: none;
}

// table
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
	padding: 10px 8px !important;
}

// panel
.p-panel.p-panel-toggleable .p-panel-header {
	padding: 5px 12.5px;
}

.p-panel .p-panel-header {
	padding: 6px 12.5px;
	border: 1px solid $color-primary;
	background: $color-primary;
	color: $color-white;
	border-top-right-radius: 8px !important;
	border-top-left-radius: 8px !important;
}

.p-panel .p-panel-header .p-panel-header-icon {
	color: $color-white;
}

.p-panel .p-panel-footer {
	padding: 5px 12.5px;
}

.p-timeline {
	overflow: auto;
	max-height: 55vh !important;
}

.p-panel .p-panel-content {
	padding: 10px 0 !important;
}

// .p-panel .p-panel-header .p-panel-header-icon {
// 	width: 15px !important;
// 	height: 15px !important;
// }

// disable
// .p-disabled,
// .p-component:disabled {
// 	background: $color-gray-light !important;
// 	opacity: 0.9 !important;
// }

// chips
.p-multiselect {
	display: flex !important;
	justify-content: space-between !important;
}

.p-multiselect-label {
	display: flex !important;
	flex-wrap: wrap;
	// flex-direction: column !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
	// margin-bottom: 5px !important;
	margin-bottom: 0.5rem;
}

.p-chips-multiple-container,
.p-multiselect-label-container {
	overflow: auto !important;
	max-height: 200px;
}

.p-datatable-scrollable-header-box {
	padding-right: 0 !important;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer,
.p-datatable-scrollable-footer-table {
	background: none !important;
}

.p-timeline-event {
	min-height: unset !important;
}

.p-button.p-button-text {
	// border: 1px solid $color-gray;
}

thead {
	border-top: 1px solid #c8c8c8 !important;
}

.p-datatable-scrollable .p-datatable-thead > tr > th,
.p-datatable-scrollable .p-datatable-tbody > tr > td,
.p-datatable-scrollable .p-datatable-tfoot > tr > td {
	flex: none !important;
}

// PrimeNg table RWD
@media screen and (max-width: 960px) {
	.p-datatable-resizable,
	.p-datatable-scrollable {
		.p-datatable-tbody {
			display: table-caption !important;
		}

		.p-datatable-thead > tr > th,
		.p-datatable-tfoot > tr > td {
			display: none !important;
		}

		.p-datatable-tbody > tr {
			display: flex;
			flex-direction: column;
			// border-bottom: 1px solid grey;

			> td {
				text-align: left;
				display: block;
				border: 0 none !important;
				width: 100% !important;
				float: left;
				clear: left;
				justify-content: flex-start !important;

				.p-column-title {
					text-align: right;
					padding: 0.4rem;
					min-width: 30%;
					display: inline-block !important;
					margin: -0.4rem 1rem -0.4rem -0.4rem;
					font-weight: bold;
				}

				.p-progressbar {
					margin-top: 0.5rem;
				}
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	.p-paginator-bottom {
		.p-paginator-current {
			display: none !important;
		}

		p-dropdown {
			display: none !important;
		}
	}
}

// meaasge padding樣式
.p-message {
	margin: 0 !important;
}

.p-message .p-message-wrapper {
	display: block;
	padding: 12.5px 17.5px;
}

// dropdown 使用 addon
.p-inputgroup p-dropdown {
	display: flex;
	align-items: stretch;
	justify-content: center;
}

// 頁尾
.p-paginator .p-paginator-current,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-pages .p-paginator-page {
	background-color: transparent;
	border: 0 none;
	color: #6c757d;
	min-width: 30px;
	height: 30px;
	margin: 1.3px;
	transition: box-shadow 0.2s;
	// border-radius: 50%;
}

// p-steps 底色
.p-steps {
	.p-steps-item .p-menuitem-link .p-steps-number {
		background: $annotate;
		border: 0px none;
		color: $color-white;
	}

	.p-steps-item.p-highlight .p-steps-number {
		width: 32px;
		height: 32px;
		background: $primary-dark;
		border: 0px none;
		color: $color-white;
	}

	.p-steps-item .p-menuitem-link {
		background: transparent;
	}

	.p-steps-item .p-steps-title {
		font-weight: 500;
	}

	.p-steps-item.p-disabled .p-steps-title {
		font-weight: 500;
		color: #605f5f;
	}

	.p-steps-item {
		&:first-child::before {
			width: 50%;
			left: unset;
			right: 0;
		}

		&:last-child::before {
			width: 50%;
		}

		&::before {
			border-top: 1px solid $primary-dark;
		}
	}

	.p-disabled,
	.p-component:disabled {
		opacity: 1;
	}
}

// split button 樣式
.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

// side-bar 樣式
.sidebar-notifications {
	max-height: 80vh;
	display: block;
	overflow: auto;
	margin-right: -10px;
	margin-left: -10px;
}

.p-sidebar .p-sidebar-header {
	padding: 12.5px;
}

@media only screen and (max-width: 425px) {
	.p-toast {
		width: 300px !important;
		opacity: 1;
		font-size: 0.875rem;

		.pi {
			font-size: 1.563rem;
		}
	}
}

/* -------------------------------------------------------------------------- */
/*                                  accordion                                 */
/* -------------------------------------------------------------------------- */

.p-accordion {
	.p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
	.p-accordion-header .p-accordion-header-link,
	.p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
	.p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
	.p-accordion-content {
		border: 0px none;
		color: map-get($text-colors, "primary");
	}

	.p-accordion-content {
		padding: 1.25rem 12.8px;
	}

	.p-accordion-header .p-accordion-header-link,
	.p-accordion-header:not(.p-disabled) .p-accordion-header-link {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 0.8rem;
		font-size: 1.125rem;
		font-weight: 700;
		text-align: center;
		border-radius: unset;
		background: #ffe0a5 !important;
	}

	.p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
		box-shadow: unset;
	}
}
