// Material(bootstrap跳窗是1050)
.cdk-overlay-container {
    z-index: 1060;
}

// fontawesome大小
.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%;
}
