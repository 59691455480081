.btn {
	&__footer {
		display: flex;
		justify-content: flex-end;

		& > button {
			margin-right: 3px 10px;
		}
	}

	&__function {
		justify-content: flex-end;

		& > button {
			margin-right: 5px;
		}
	}

	&__action {
		display: flex;
		align-items: baseline;

		& > * {
			margin-right: 3px;
		}

		& > a {
			margin-right: 3px;
		}

		& > button {
			margin-right: 3px;
		}
	}

	&__stepper {
		display: flex;
		justify-content: space-between;

		& > button {
			margin-right: 3px 10px;
		}
	}

	&__fab {
		font-size: 0.625rem;
		background-color: var(--color-primary) !important;
	}

	&__content {
		text-align: right;
	}

	&__return {
		margin-bottom: 13px;
	}
}

.battery {
	&-success {
		color: #739e73 !important;
	}

	&-danger {
		color: #a60916 !important;
	}

	&-warning {
		color: #c79121 !important;
	}

	&-grey {
		color: #6c757d !important;
	}
}

.btn__top {
	position: fixed !important; /* Fixed/sticky position */
	bottom: 15rem; /* Place the button at the bottom of the page */
	right: 4rem; /* Place the button 3px from the right */
	z-index: 99; /* Make sure it does not overlap */
	background-color: var(--color-white);
	cursor: pointer !important; /* Add a mouse pointer on hover */
}
