.card-header {
	padding: 8px 12px;
	border-top-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
	background-color: $color-primary-dark;
	font-weight: 400;
	color: $color-white;
	font-size: $default-font-size;
	border: 1px solid $color-primary-dark;
}

.card-title {
	border-left: 5px solid #a57225;
}

.card {
	margin: 0px 11px;
	font-size: $default-font-size;
	border: none;
	word-wrap: inherit !important;
	background: $color-white;
	border-radius: 12px;
}

.card-footer {
	padding: 5px 12px !important;
}

.fixed-ribbon #content {
	padding-top: 0 !important;
}

body.smart-style-2 {
	background: #ededed;
}

tr.mat-header-row {
	height: 65px !important;
}

/** 狀態styles **/
.status {
	font-size: 0.75rem;
	font-weight: 600;
	padding: 3px 5px;
	width: 120px;
	border: none;
	border-radius: 1px;
	color: var(--color-white);
	text-align: center;

	&-0 {
		background-color: #2c4e83;
	}

	&-1 {
		background-color: #52b2bb;
	}

	&-2 {
		background-color: #d77254;
	}

	&-3 {
		background-color: #687f87;
	}
}

/** 主題類型 **/
.type {
	font-size: $default-font-size;
	font-weight: 500;
	padding: 5px 10px;
	min-width: 120px;
	border: none;
	border-radius: 1px;
	color: var(--color-white);
	text-align: center;

	&-1 {
		background-color: #2c4e83;
	}

	&-2 {
		background-color: #52b2bb;
	}

	&-3 {
		background-color: #d77254;
	}

	&-4 {
		background-color: #687f87;
	}

	&-5 {
		background-color: #96341c;
	}

	&-6 {
		background-color: #5d4c86;
	}

	&-7 {
		background-color: #235b54;
	}

	&-8 {
		background-color: #2f2aa0;
	}

	&-9 {
		background-color: #228c68;
	}

	&-10 {
		background-color: #c56133;
	}

	&-11 {
		background-color: #277da7;
	}

	&-12 {
		background-color: #29bdab;
	}

	&-13 {
		background-color: #e68f66;
	}

	&-14 {
		background-color: #37294f;
	}

	&-15 {
		background-color: #3998f5;
	}

	&-16 {
		background-color: #3750db;
	}
}

/**  操作行為 **/
.actiontype {
	font-size: $default-font-size;
	font-weight: 500;
	padding: 5px 10px;
	min-width: 120px;
	border: none;
	border-radius: 1px;
	color: var(--color-white);
	text-align: center;

	&-1 {
		background-color: #eab54b;
	}

	&-2 {
		background-color: #e29735;
	}

	&-3 {
		background-color: #d55f2d;
	}
}

.fileUpload,
.fileDownload {
	position: relative;
	overflow: hidden;

	input.upload {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding: 0;
		font-size: 1.25rem;
		cursor: pointer;
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

// lims狀態
.reviewstatus {
	padding: 6px 10px;
	width: 120px;
	// border: none;
	font-weight: 700;
	border-radius: 1px;
	// color: var(--color-white);
	text-align: center;

	&-0 {
		color: $color-warning;
		border: 1px solid $color-warning;
	}

	&-1 {
		color: $color-danger;
		border: 1px solid $color-danger;
	}

	&-2 {
		color: $color-success;
		border: 1px solid $color-success;
	}

	&-3 {
		color: $color-primary;
		border: 1px solid $color-primary;
	}

	&-4 {
		color: $color-danger;
		border: 1px solid $color-danger;
	}

	&-5 {
		color: #eda345;
		border: 1px solid #eda345;
	}

	&-6 {
		color: $color-blue;
		border: 1px solid $color-blue;
	}

	&-7 {
		color: $color-info;
		border: 1px solid $color-info;
	}

	&-8 {
		color: #487dbd;
		border: 1px solid #487dbd;
	}

	&-9 {
		color: #7588a2;
		border: 1px solid #7588a2;
	}

	&-10 {
		color: $color-warning;
		border: 1px solid $color-warning;
	}

	&-11 {
		color: #7588a2;
		border: 1px solid #7588a2;
	}
}

.text-help {
	color: #633ab4;
}

.text-error {
	margin: 4px;
	margin-bottom: 32px;
	font-size: 0.938rem;
	color: $danger;
}

#content {
	padding: 32px;
}

.page-header {
	height: 33px;
	margin-bottom: 12px;
	font-size: 1.375rem;
	font-weight: 500;
	line-height: 33px;
}

.table-title {
	height: 60px;
	padding: 13px 16px;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 34px;
	background-color: $primary-light;
}

a:not([href]) {
	color: inherit;
	text-decoration: none;
}

a:hover {
	color: inherit
}

// ! 無障礙客製
@mixin show-on-focus {
	width: auto !important;
	height: auto !important;
	border: 2px dashed #976401 !important;
}

@mixin hidden-without-focus {
	width: 0 !important;
	height: 0 !important;
	overflow: hidden !important;
}

#goto_page_content {
	@include hidden-without-focus;

	color: #6e4900;
	font-weight: bolder !important;
	font-size: 1rem !important;
	margin-right: 5px;

	&:focus {
		@include show-on-focus;
	}
}

// ! 上方 nav
#AU {
	margin-left: 10px;
	color: transparent;

	&:focus,
	&:hover {
		border: 2px dashed #976401 !important;
		color: #6e4900;
	}
}

// ! 中心主要內容
#AC {
	color: transparent;

	&:focus,
	&:hover {
		border: 2px dashed #976401 !important;
		color: #6e4900;
	}
}

// ! 下方 footer
#AZ {
	color: transparent;

	&:focus,
	&:hover {
		// border: 2px dashed #976401 !important;
		// color: #a76f00;
		border: 1px dashed #976401 !important;
		background-color: white;
		color: #976401 !important;
		font-weight: bolder !important;
	}
}

// ! 左方 navBar
#AL {
	@include hidden-without-focus;
	color: transparent;

	&:focus,
	&:hover {
		border: 2px dashed #976401 !important;
		color: #6e4900;
		@include show-on-focus;
	}
}


// ! 焦點時加 border
.custom-focus {
	&:focus {
		border: 3px dashed #976401 !important;
	}
}

// ! 焦點時加 border but with heavier highlight
.custom-focus-heavier {
	&:focus {
		border: 3px dashed #976401 !important;
		background-color: white;
		color: #976401 !important;
		font-weight: bolder !important;
	}
}

.p-inputgroup-addon {
	&:focus {
		border: 1px solid #3b82f6 !important;
	}
}