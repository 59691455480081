.clearfix:before,
.clearfix:after {
	content: ' ';
	display: table;
}

.clearfix:after {
	clear: both;
}

aside {
	display: block;
	min-height: 100%;
	background: white;
	border-right: 1px solid $primary-dark;
}

.fixed-navigation nav > ul {
	height: 100%;
	width: 100%;
	padding-right: 0px;
	color: inherit;
	overflow-x: hidden;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
}

nav ul li {
	border-bottom: 1px solid $grey3;
	a,
	.menu-item-parent {
		font-size: 1.125rem;
	}

	a {
		color: $text-primary;
		padding: 16px;
		&:active {
			color: $white !important;
		}
	}
}

.mobile-detected.fixed-navigation nav > ul {
	padding-right: 0px !important;
}

.login-info > span {
	height: 38px;
	display: block;
	padding: 0 10px;
}

#left-panel {
	padding-top: 64px;
	color: $text-primary;
}

.minified nav > ul > li {
	border-bottom: 0px solid $color-primary;
	border-top: 0px solid $color-primary;
	// margin: 10px 0px;
}

.minified nav > ul > li > a > .menu-item-parent {
	display: none;
	position: absolute;
	left: 45px;
	top: -3px;
	width: 197px;
	height: 38px;
	line-height: 38px;
	background-color: $color-primary;
	color: $color-white;
	z-index: 3;
	-webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
	border-left: 1px solid $color-primary;
	border-top: 1px solid $color-primary;
	border-bottom: 1px solid $color-primary;
	padding-left: 12px;
}

.minified nav > ul > li > ul {
	display: none !important;
	position: absolute;
	left: 45px;
	width: 210px;
	z-index: 5;
	border: 1px solid $color-primary;
	min-height: 180px;
	-webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
	background: $color-primary;
	margin-top: -3px;
	overflow: hidden;
}

.minifyme {
	width: 43px;
	height: 46px;

	margin-left: 8px;
	position: unset;
	background-color: $color-white !important;
	border-radius: 8px;
	border: 0px none !important;
	font-size: 1.75rem !important;
	em,
	i {
		height: 28px;
		line-height: 28px;
		color: $color-primary-dark !important;
		&.fa-angles-right {
			display: none;
		}
		&.fa-angles-left {
			display: block;
		}
	}
}
.minified .minifyme {
	height: 50px;
	background-color: $color-white !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: none;

	em,
	i {
		height: 43px;
		line-height: 43px;
		color: $color-primary-dark !important;
	}
	.fa-angles-right {
		display: block;
	}
	.fa-angles-left {
		display: none;
	}
}

.minified .login-info {
	display: none;
}

.login-info {
	width: unset;
	height: unset;
	padding: 16px;
	font-size: 1.125rem;
	color: inherit;
	border-bottom: 1px solid $grey3;
}

.minified.fixed-navigation nav,
.menu-on-top .slimScrollDiv,
.menu-on-top nav {
	margin-top: 0px;
}

.fixed-header #header {
	position: absolute;
	top: 0;
}

@media screen and (min-width: 979px) {
	#left-panel {
		padding-top: 80px;
	}

	.fixed-header #header {
		position: fixed;
	}
}
