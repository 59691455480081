#extr-page {
	width: 100%;
	min-height: calc(100vh - 64px);
	background-color: $primary-light;
	& #main {
		position: relative;
		min-height: calc(100vh - 80px);
		background: transparent;
	}
}

.login {
	&__bg {
		min-height: calc(100vh);
	}
	&__form {
		display: block;
		margin-top: 110px;
		max-width: 360px;
		width: 100%;
	}

	&__title {
		font-size: 1.75rem;
		font-weight: 500;
		text-align: center;
		&:nth-child(2) {
			margin-bottom: 40px;
		}
	}

	&__forget {
		display: flex;
		justify-content: end;
		a,
		a:active {
			font-weight: 700;
			color: $primary-dark;
		}
	}

	&__register {
		display: flex;
		justify-content: center;
		a,
		a:active {
			font-weight: 500;
			color: $primary-dark;
		}
	}

	&__capCha {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 56px;
		border: 1px solid #ced4da;
		border-radius: 10px;
		img {
			display: block;
			height: 50px;
			object-fit: cover;
		}
	}

	&__label {
		font-size: 1rem;
		font-weight: 400;
	}

	&__card,
	&__step {
		width: calc(100% - 22px);
		max-width: 784px;
	}

	&__card {
		.form-sm {
			max-width: 320px;
		}
	}

	&__step {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	// ! end
	&__box {
		background: rgba($color-white, 0.9);
		padding: 40px;
		border-radius: 24px;
		box-shadow: 0 4px 10px 4px rgba($color-gray, 0.3);
	}

	&__form {
		& > mat-form-field {
			width: 100%;
			font-size: 1.25rem;
		}
	}

	&__button {
		border: 0;
		outline: none;
		border-radius: 5px;
		padding: 10px 15px;
		font-size: $default-font-size;
		font-weight: 600;
		background: $color-primary;
		color: $color-white;
		transition: all 0.5s ease;
		appearance: none;
		margin-bottom: 10px;

		&:hover,
		&:focus {
			background: darken($color-primary-light, 8%);
		}
	}
}

input {
	font-size: 0.938rem;
	color: $color-white;

	&:focus {
		color: $color-gray;
	}
}

@media screen and (min-width: 979px) {
	#extr-page {
		min-height: calc(100vh - 80px);
	}
}
